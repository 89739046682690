<template>
    <v-container grid-list-xl class="pa-0 us-form-mother">
       
        <v-form ref="newsform">
            <v-card elevation="0">
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="salutation" :items="salutation" :label="$t('forms.labelanrede')"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="titel" :items="titel" :label="$t('forms.labeltitel')"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="firstname" dense v-model="firstname" :rules="[() => !!firstname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.vorname')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="lastname" dense v-model="lastname" :rules="[() => !!lastname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.nachname')" required></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="company" dense v-model="company" :rules="[() => !!company || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.company')" required></v-text-field>
                        </v-col> -->
                        <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="position" dense v-model="position" :rules="[() => !!position || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.position')" required></v-text-field>
                        </v-col> -->
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-text-field outlined dense ref="address" v-model="address" :rules="[() => !!address || $t('notice.empty'), () => (!!address && address.length <= 25) || $t('notice.minlang5')]" :label="$t('global.stern') + ' ' + $t('forms.strasse')" required></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined dense ref="zip" v-model="zip" :rules="rules.zipRules" :label="$t('global.stern') + ' ' + $t('forms.plz')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined dense ref="city" v-model="city" :rules="[() => !!city || $t('notice.empty')]" :label="$t('global.stern') + ' ' + $t('forms.ort')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="email" dense v-model="email" :rules="[() => !!email || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.email')" required></v-text-field>
                        </v-col>
                         <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="tel" dense v-model="tel" :rules="[() => !!tel || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.tel')"  required ></v-text-field>
                    </v-col>


                        <!-- <v-col cols="12" class="mt-3">          
                      <VueRecaptcha sitekey="6LdTarUUAAAAABJ9PzpzT7rmM8GNclGvVRlm2VEV" class="us-make-responsiv" @verify="captcha = true"></VueRecaptcha>
                    </v-col>  -->
                    </v-row>
                    <v-row>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div>{{ $t("global.datenschutz") }} <a href="#/article/Impressum">Impressum</a> und <a href="#/article/Datenschutz">Datenschutz</a></div>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div>
                                <v-checkbox ref="akzeptieren" v-model="akzeptieren" :label="$t('global.agbunddatens')" :rules="[() => !!akzeptieren || $t('notice.empty')]" :error-messages="errorMessages" required></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="pt-3">
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="info" width="100%" @click="resetForm()">{{ $t("registration.deleteform") }}</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="primary" width="100%" @click="submitForm()">{{ $t("global.abschicken") }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
        <v-dialog v-model="dialogDanke" width="500" >
            <v-card> 
                <v-card-title class="headline grey lighten-2" primary-title >
                    {{$t('global.headFormNews')}}
                    </v-card-title>
                <v-card-text class="pt-5">
                    {{$t('forms.nlangemeldet')}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions> <v-spacer></v-spacer> <v-btn color="primary" text @click="dialogDanke = false" >
                    {{$t('global.close')}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from "vuex"

import VueRecaptcha from "vue-recaptcha"
export default {
    components: {
        VueRecaptcha
    },
    data() {
        return {
            dialogDanke: false,
            valid: false,
            salutation: [],
            titel: [],
            position: null,
            company: null,
            akzeptieren: null,
            firstname: null,
            address: null,
            zip: null,
            city: null,
            lastname: null,
            email: null,
            tel: null,
            formHasErrors: false,
            errorMessages: "",
            rules: {
                zipRules: [v => !!v || this.$t("registration.ziperror"), v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || this.$t("registration.ziperror")]
            },
            isCaptcha: true,
            zweiterSchluessel: "6LdTarUUAAAAAMXMsm1xxDYnLuY_X0gCDimYoaiq"
        }
    },
    computed: {
        ...mapState(["UserStore", "WebsiteStore"]),

        form() {
            return {
                Anrede:this.saluation_select,
                Titel:this.titel_select,
                firstname: this.firstname,
                lastname: this.lastname,
                address: this.address,
                zip: this.zip,
                city: this.city,
                email: this.email,
                tel: this.tel,
                akzeptieren: this.akzeptieren
            }
        }
    },
    mounted() {
        Object.keys(this.$t("forms.anrede")).forEach(a => {
            this.salutation.push(this.$t("forms.anrede." + a))
        })
        Object.keys(this.$t("forms.titel")).forEach(a => {
            this.titel.push(this.$t("forms.titel." + a))
        })
    },
    created() {},
    destroyed() {},
    methods: {
        resetForm() {
            this.$refs["newsform"].reset()
            this.WebsiteStore.drawer = false
        },
        async submitForm() {
            
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) {
                    this.formHasErrors = true
                }
            })

            if (this.$refs.Gutschein.validate()) {
                let formData = new FormData()
                for (const key in this.form) {
                    formData.append(key, this.form[key])
                }

                fetch(process.env.BASE_URL + "php/mail_action.php", { method: "POST", body: formData })
                    .then(res => res.text())
                    .then(jsonData => {
                        this.resetForm()                        
                        this.dialogDanke = true;                        
                        this.dialogmessage = this.$t('forms.nachricht');
                        this.dialoghead= this.$t('forms.mailversendet');                    
                    })
                    .catch(error => {                        
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.errornachricht');
                        this.dialoghead= this.$t('forms.fehler');
                        return
                    })
            }


        }
    }
}
</script>


<style lang="scss">
</style>