<template>     
       <v-container grid-list-xl class="pa-0 us-form-mother">
            
            
            <v-form ref="anfrageJourneyPausch">  

              <v-card elevation="0" > 
                <v-card-text>               
                  <v-row  dense >
                     <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                       <v-select outlined dense ref="salutation" :items="salutation" :label="$t('forms.labelanrede')" ></v-select>                    
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-select outlined dense ref="titel" :items="titel" :label="$t('forms.labeltitel')" ></v-select>
                    </v-col> -->
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="company" dense v-model="company" :rules="[() => !!company || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+ $t('forms.company')"  required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="ansprechpartner" dense v-model="ansprechpartner" :rules="[() => !!ansprechpartner || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.ansprechpartner')"  required ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="firstname" dense v-model="firstname" :rules="[() => !!firstname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('forms.vorname')"  required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="lastname" dense v-model="lastname" :rules="[() => !!lastname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('forms.nachname')"  required ></v-text-field>
                    </v-col> -->
      
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                      <v-text-field outlined  dense  ref="address" v-model="address" :label="$t('forms.strasse')" counter="5" ></v-text-field>
                    </v-col>


                    
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                        <v-text-field outlined  dense ref="zip" v-model="zip" :label="$t('forms.plz')" counter="5" ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                        <v-text-field outlined  dense ref="city" v-model="city" :label="$t('forms.ort')"  ></v-text-field>
                    </v-col>                                  
                    <!-- <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                      <v-select outlined dense ref="country" v-model="country" :rules="[() => !!country || $t('notice.empty')]" :items="countries" :label="$t('forms.land')" required ></v-select>
                    </v-col> -->
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="email" dense v-model="email" :rules="[() => !!email || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.email')"  required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="tel" dense v-model="tel" :rules="[() => !!tel || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.tel')"  required ></v-text-field>
                    </v-col>

                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="6"> 
                       <v-text-field outlined  ref="reiseziel" dense v-model="reiseziel" :rules="[() => !!reiseziel || $t('notice.empty')]" :error-messages="errorMessages"  :label="$t('global.stern') +' '+  $t('forms.reiseziel')" required  ></v-text-field>
                    </v-col>

                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="6"> 
                       <v-text-field outlined  ref="anzahlpersonen" dense v-model="anzahlpersonen" :label="$t('forms.anzP')" type="number" :min="1" :max="100"></v-text-field>
                    </v-col>



                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="6"> 
                       <v-text-field outlined  ref="ez" dense v-model="ez" :label="$t('global.ez')" type="number" :min="0" :max="100"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="6"> 
                       <v-text-field outlined  ref="dz" dense v-model="dz" :label="$t('global.dz')" type="number" :min="0" :max="100"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="6" class="pt-2"> 
                       <v-text-field outlined  ref="mz" dense v-model="mz" :label="$t('global.mz')" type="number" :min="0" :max="100"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="6"> 
                       <v-select outlined dense ref="foot" v-model="selverpflegung" :items="verpflegung" :label="$t('global.verpflegung')"></v-select>
                    </v-col>
                    

                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                        <v-text-field outlined  ref="hotelkat" dense v-model="hotelkat" :label="$t('global.hotelkategorie')" ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                        <v-text-field outlined  ref="budget" dense v-model="budget" :label="$t('global.budget')" ></v-text-field>
                    </v-col>

                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12"> 
                        <v-select outlined dense ref="verein" v-model="verein" :items="vereinsfahrt" :label="$t('global.vereinsfahrt')"></v-select>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12" class="pt-2">          
                        <v-text-field outlined  ref="print" dense v-model="print" :label="$t('global.printbezug')" ></v-text-field>
                    </v-col>



                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">  
                        <v-menu v-model="datepickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field outlined  v-model="termin" :label="$t('forms.termin')"  dense v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="datepicker" year-icon="fa-calendar-alt" prev-icon="fa-arrow-alt-circle-left" next-icon="fa-arrow-alt-circle-right" color="primary" locale="de-de" scrollable width="300" @input="datepickerMenu = true">
                                <v-spacer></v-spacer>
                                <v-btn color="info" text @click="setTermin()">{{ $t('global.cancel') }}</v-btn>
                                <v-btn color="primary" text @click="setTermin(1)">{{ $t('global.set') }}</v-btn>
                            </v-date-picker>
                        </v-menu>                    
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12"> 
                        <v-menu v-model="datepickerMenu2" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field outlined  v-model="altertermin" :label="$t('forms.altertermin')"  dense v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="datepicker2" year-icon="fa-calendar-alt" prev-icon="fa-arrow-alt-circle-left" next-icon="fa-arrow-alt-circle-right" color="primary" locale="de-de" scrollable width="300" @input="datepickerMenu2 = true">
                                <v-spacer></v-spacer>
                                <v-btn color="info" text @click="setTermin2()">{{ $t('global.cancel') }}</v-btn>
                                <v-btn color="primary" text @click="setTermin2(1)">{{ $t('global.set') }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>                    
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                     <v-textarea ref="formmessage" outlined :label="$t('global.sons1')" v-model="formmessage" :rules="[() => !!formmessage || $t('notice.empty')]" :error-messages="errorMessages" required ></v-textarea>
                    </v-col>
                    <!-- <v-col cols="12" class="mt-n3">          
                      <VueRecaptcha sitekey="6LdTarUUAAAAABJ9PzpzT7rmM8GNclGvVRlm2VEV" class="us-make-responsiv" @verify="captcha = true"></VueRecaptcha>
                    </v-col>  -->
                    <v-col cols="12" class="mt-n3">          
                      <!-- <timeselector v-model="time" placeholder="Abfahrtszeit"></timeselector> -->
                    </v-col> 
                  </v-row>
                  
                  
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12"> 
                          <div>{{ $t("global.datenschutz") }} <a href="#/article/Impressum" >Impressum</a> und <a href="#/article/Datenschutz" >Datenschutz</a></div>            </v-col>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                        <div class="mt-n5 ml-n5">
                            <v-checkbox ref="akzeptieren" v-model="akzeptieren" :label="$t('global.agbunddatens')" :rules="[() => !!akzeptieren || $t('notice.empty')]" :error-messages="errorMessages"  required ></v-checkbox>
                        </div>
                    </v-col>
                  
                  
                  <v-row class="pt-3">
                        <v-col cols="12" xs="12" sm="6">
                              <v-btn color="info" width="100%" @click="resetForm()" >{{$t('registration.deleteform')}}</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="primary" width="100%" @click="submit()" >{{$t('global.abschicken')}}</v-btn> 
                        </v-col>
                  </v-row> 
                              
              </v-card-text>
            </v-card>
          </v-form>  

          <v-dialog v-model="dialogDanke" width="500" >
                <v-card> 
                  <v-card-title class="headline grey lighten-2" primary-title >
                    {{dialoghead}}
                  </v-card-title>
                <v-card-text class="pt-5">
                  {{dialogmessage}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions> <v-spacer></v-spacer> <v-btn color="primary" text @click="dialogDanke = false" >
                  {{$t('global.close')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


        </v-container>


</template>

<script>    
    import { mapState } from "vuex"
    import moment from 'moment'
    import VueRecaptcha from 'vue-recaptcha';

    export default {   
    components: { 
      VueRecaptcha 
    },
    data() {
      return {
        dialogDanke:false,
        dialogmessage:null,
        dialoghead:null,
        
        datepicker2: new Date().toISOString().substr(0, 10), 
        datepickerMenu2: false,  
        datepicker: new Date().toISOString().substr(0, 10), 
        datepickerMenu: false,  

        selverpflegung:null,
        akzeptieren:false,
        valid: false,       
        countries: [],
        salutation: [],
        verpflegung: [],
        vereinsfahrt: [],
        verein:null,
        print:null,
        titel: [],        
        position:null,
        ansprechpartner:null,
        ez:0,
        dz:0,
        mz:0,
        anzahlpersonen:1,
        altertermin:null,
        budget:null,
        hotelkat:null,
        termin: null,
        reiseziel:null,
        company:null,
        firstname: null,
        lastname: null,
        address: null,
        city: null,
        zip: null,
        country: null,
        tel: null,
        email: null,
        formmessage: null,
        formHasErrors: false,
        errorMessages: '',
        rules: {
            zipRules: [v => !!v || this.$t('registration.ziperror') , v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v)  || this.$t('registration.ziperror') ],          
        },        
        isCaptcha:true,
        zweiterSchluessel:'6LdTarUUAAAAAMXMsm1xxDYnLuY_X0gCDimYoaiq',        
      };
    },
    computed: {
        ...mapState(["UserStore","WebsiteStore"]),

        form () {
            return {
              Formular: 'Buchungsanfrage pauschal',
              Firma: this.company,
              Ansprechpartner: this.ansprechpartner,
              Straße: this.address,
              PLZ: this.zip,
              Ort: this.city,
              Email: this.email,
              Telefon: this.tel,
              Reiseziel: this.reiseziel,
              Personenzahl: this.saluation_select,
              Einzelzimmer: this.ez,
              Doppelzimmer: this.dz,
              Dreibettzimmer: this.mz,
              Verpflegung: this.selverpflegung,
              Hotelkategorie: this.hotelkat,
              Budget: this.budget,
              vereinsfahrt: this.verein,
              Katalogseite: this.print,
              Termin: this.termin,
              Alternativtermin: this.altertermin,
              Sonstiges: this.formmessage
        }
        }
    },
    mounted(){  
        Object.keys(this.$t('forms.anrede')).forEach(a => { this.salutation.push( this.$t('forms.anrede.'+a) ) })   
        Object.keys(this.$t('forms.titel')).forEach(a => { this.titel.push( this.$t('forms.titel.'+a) ) })   
        Object.keys(this.$t('forms.countries')).forEach(a => { this.countries.push( this.$t('forms.countries.'+a) ) })   
        Object.keys(this.$t('selverpflegung')).forEach(a => { this.verpflegung.push( this.$t('selverpflegung.'+a) ) })   
        Object.keys(this.$t('janein')).forEach(a => { this.vereinsfahrt.push( this.$t('janein.'+a) ) 
    })   
    },   
    created () {      
       
    },
    destroyed () {       
        
    },
    methods: {                  
          setTermin(set) { 
            if(set==1) 
                { 
                    this.termin = moment(String(this.datepicker)).format('DD.MM.YYYY');; 
                } 
            this.datepickerMenu = false; 
        },
          setTermin2(set) { 
            if(set==1) 
                { 
                    this.altertermin = moment(String(this.datepicker2)).format('DD.MM.YYYY');
                } 
            this.datepickerMenu2 = false; 
        },
          resetForm () {
            this.$refs['anfrageJourneyPausch'].reset();
            this.WebsiteStore.drawer = false;
          },
          submit () {
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) {
                    this.formHasErrors = true
                }
            })

            if (this.$refs.anfrageJourneyPausch.validate()) {
                let formData = new FormData()
                for (const key in this.form) {
                    formData.append(key, this.form[key])
                }

                fetch(process.env.BASE_URL + "php/mail_action.php", { method: "POST", body: formData })
                    .then(res => res.text())
                    .then(jsonData => {
                        this.resetForm()
                        
                        this.dialogDanke = true;
                        
                        this.dialogmessage = this.$t('forms.nachricht');
                        this.dialoghead= this.$t('forms.mailversendet');
                       
                    })
                    .catch(error => {
                        
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.errornachricht');
                        this.dialoghead= this.$t('forms.fehler');
                       
                        return
                    })
            }





      },
    }  
      
  }
</script>


<style lang="scss">
    .v-icon.notranslate {
        margin-left:5px;
    }
</style>