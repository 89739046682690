<template>
    <v-container grid-list-xl class="pa-0 us-form-mother">

        <v-form ref="Gutschein">
            <v-card elevation="0">
                <Article alias="Newsletter" class="pa-0 ma-0 ml-5"></Article>


               <!-- <iframe class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://x1out.mjt.lu/wgt/x1out/4i1/form?c=78725875" width="100%" height="340"></iframe> -->
               <MailJetNL ref="MailJetNL" Nltype="plain"></MailJetNL>

            </v-card>
        </v-form>


        <v-dialog v-model="dialogDanke" width="500" >
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title >
                    {{$t('forms.mailversendet')}}
                    </v-card-title>
                <v-card-text class="pt-5">
                    {{$t('forms.nachricht')}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions> <v-spacer></v-spacer>
                <v-btn color="primary" text @click.stop="navigate('/')" > {{$t('global.close')}} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



    </v-container>
</template>

<script>

    import { mapState } from "vuex"
    import moment from 'moment'


    import Article from "../Article"
    import MailJetNL from "@/components/Newsletter/mailjet.vue"

    export default {
    components: {
        MailJetNL, Article
    },
    data() {
      return {
        empfaenger:null,
        betrag:null,
        dialogDanke:false,
        dialogmessage:null,
        dialoghead:null,
        saluation_select:null,
        titel_select:null,
        akzeptieren:false,
        valid: false,
        countries: [],
        salutation: [],
        titel: [],
        firstname: null,
        lastname: null,
        address: null,
        city: null,
        zip: null,
        country: null,
        tel: null,
        email: null,
        formmessage: null,
        formHasErrors: false,
        errorMessages: '',
        rules: {
            zipRules: [v => !!v || this.$t('registration.ziperror') , v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v)  || this.$t('registration.ziperror') ],
        },
        isCaptcha:true,
        zweiterSchluessel:'6LdTarUUAAAAAMXMsm1xxDYnLuY_X0gCDimYoaiq',
      };
    },
    computed: {
        ...mapState(["UserStore","WebsiteStore"]),

        form () {
            return {
              Formular: 'Reisegutschein',
              Titel: this.saluation_select,
              Anrede: this.titel_select,
              Vorname: this.firstname,
              Nachname: this.lastname,
              Straße: this.address,
              PLZ: this.zip,
              Ort: this.city,
              Land:this.country,
              Telefon: this.tel,
              Email: this.email,
              Telefon: this.tel,
              Gutscheinbetrag: this.betrag,
              Empfänger: this.empfaenger,
              Anliegen: this.formmessage
        }
        }
    },
    mounted(){
        Object.keys(this.$t('forms.anrede')).forEach(a => { this.salutation.push( this.$t('forms.anrede.'+a) ) })
        Object.keys(this.$t('forms.titel')).forEach(a => { this.titel.push( this.$t('forms.titel.'+a) ) })
        Object.keys(this.$t('forms.countries')).forEach(a => { this.countries.push( this.$t('forms.countries.'+a) ) })
    },
    created () {

    },
    destroyed () {

    },
    methods: {
        navigate(link, forceLoad = false) {
            core.navigate({ path: link }, forceLoad)
        },
        setTermin(set) {
            if(set==1)
                {
                    this.termin = moment(String(this.datepicker)).format('DD.MM.YYYY');;
                }
            this.datepickerMenu = false;
        },
          setTermin2(set) {
            if(set==1)
                {
                    this.altertermin = moment(String(this.datepicker2)).format('DD.MM.YYYY');;
                }
            this.datepickerMenu2 = false;
        },
          resetForm () {
            this.$refs['Gutschein'].reset();
            this.WebsiteStore.drawer = false;
          },
          submit() {
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) {
                    this.formHasErrors = true
                }
            })

            if (this.$refs.Gutschein.validate()) {
                let formData = new FormData()
                for (const key in this.form) {
                    formData.append(key, this.form[key])
                }

                fetch(process.env.BASE_URL + "php/mail_action.php", { method: "POST", body: formData })
                    .then(res => res.text())
                    .then(jsonData => {
                        this.resetForm()
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.nachricht');
                        this.dialoghead= this.$t('forms.mailversendet');
                    })
                    .catch(error => {
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.errornachricht');
                        this.dialoghead= this.$t('forms.fehler');
                        return
                    })
            }
        }
    }

  }
</script>


<style lang="scss">
    .v-icon.notranslate {
        margin-left:5px;
    }
</style>