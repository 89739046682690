<template>
    <v-container grid-list-xl class="pa-0 us-form-mother">
    
        <v-form ref="Busanmieten">
            <v-card elevation="0">
                <v-card-text>

                    

                    <v-row dense>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12"> 
                            <h3 class="primary--text py-2">Allgemeine Angaben</h3> 
                        </v-col>
                         <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-text-field outlined  ref="company" dense v-model="company" :rules="[() => !!company || $t('notice.empty')]" :error-messages="errorMessages" label="Firma / Schule / Institution" required></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="salutation" v-model="saluation_select" :items="salutation" :label="$t('forms.labelanrede')"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="titel" v-model="titel_select" :items="titel" :label="$t('forms.labeltitel')"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="firstname" dense v-model="firstname" :rules="[() => !!firstname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.vorname')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="lastname" dense v-model="lastname" :rules="[() => !!lastname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.nachname')" required></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="position" dense v-model="position" :rules="[() => !!position || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.position')" required></v-text-field>
                        </v-col> -->

                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-text-field outlined  dense ref="address" v-model="address" :rules="[() => !!address || $t('notice.empty'), () => (!!address && address.length <= 25) || $t('notice.minlang5')]" :label="$t('global.stern') + ' ' + $t('forms.strasse')" required></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="zip" v-model="zip" :rules="rules.zipRules" :label="$t('global.stern') + ' ' + $t('forms.plz')" required></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="city" v-model="city" :rules="[() => !!city || $t('notice.empty')]" :label="$t('global.stern') + ' ' + $t('forms.ort')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-select outlined dense ref="country" v-model="country" :rules="[() => !!country || $t('notice.empty')]" :items="countries" :label="$t('global.stern') + ' ' + $t('forms.land')" required></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="email" dense v-model="email" :rules="[() => !!email || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.email')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="tel" dense v-model="tel" :rules="[() => !!tel || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.tel')" required></v-text-field>
                        </v-col>                        
                        </v-row>                
                        
                        
                           
                    <v-row dense>           
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12"> 
                            <h3 class="primary--text py-2">Angaben zur Fahrt</h3> 
                         </v-col>
                         <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-select outlined dense ref="interessen" v-model="interessen" :rules="[() => !!interessen || $t('notice.empty')]" :items="kundenint" :label="$t('global.stern') + ' Kundeninteresse' " required></v-select>
                        </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">  
                        
                        <v-menu v-model="datepickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field outlined  ref="termhin" v-model="termhin" :label="$t('global.stern') + ' Termin Hinfahrt'"  dense v-on="on"  :rules="[() => !!termhin || $t('notice.empty')]" :error-messages="errorMessages" required></v-text-field>
                            </template>
                            <v-date-picker v-model="datepicker" year-icon="fa-calendar-alt" prev-icon="fa-arrow-alt-circle-left" next-icon="fa-arrow-alt-circle-right" color="primary" locale="de-de" scrollable width="300" @input="datepickerMenu = true">
                                <v-spacer></v-spacer>
                                <v-btn color="info" text @click="setTermin()">{{ $t('global.cancel') }}</v-btn>
                                <v-btn color="primary" text @click="setTermin(1)">{{ $t('global.set') }}</v-btn>
                            </v-date-picker>
                             <v-time-picker v-model="timepickerab" scrollable></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12"> 
                        <v-menu v-model="datepickerMenu2" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field outlined ref="termrueck"  v-model="termrueck" :label="$t('global.stern') + ' Termin Rückfahrt'"  dense v-on="on" :rules="[() => !!termrueck || $t('notice.empty')]" :error-messages="errorMessages" required></v-text-field>
                            </template>
                            <v-date-picker v-model="datepicker2" year-icon="fa-calendar-alt" prev-icon="fa-arrow-alt-circle-left" next-icon="fa-arrow-alt-circle-right" color="primary" locale="de-de" scrollable width="300" @input="datepickerMenu2 = true">
                                <v-spacer></v-spacer>
                                <v-btn color="info" text @click="setTermin2()">{{ $t('global.cancel') }}</v-btn>
                                <v-btn color="primary" text @click="setTermin2(1)">{{ $t('global.set') }}</v-btn>
                            </v-date-picker>
                            <v-time-picker v-model="timepickeran" scrollable></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                        <v-menu ref="menuTimeAb" v-model="menuTimeAb" :close-on-content-click="false" :nudge-right="40" :return-value.sync="TimeAb" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                            <template v-slot:activator="{ on }">
                            <v-text-field outlined dense v-model="TimeAb" label="Abfahrtszeit" readonly v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="menuTimeAb" v-model="TimeAb" full-width @click:minute="$refs.menuTimeAb.save(TimeAb)" scrollable format="24hr"></v-time-picker>
                        </v-menu>
                     </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                        <v-menu ref="menuTimeAn" v-model="menuTimeAn" :close-on-content-click="false" :nudge-right="40" :return-value.sync="TimeAn" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                            <template v-slot:activator="{ on }">
                            <v-text-field outlined dense v-model="TimeAn" label="Ankunftszeit" readonly v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="menuTimeAn" v-model="TimeAn" full-width @click:minute="$refs.menuTimeAn.save(TimeAn)" scrollable format="24hr"></v-time-picker>
                        </v-menu>         
                    </v-col> 




                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="AnzPers" v-model="AnzPers"  :label="$t('global.stern') + ' Personenanzahl'" :rules="[() => !!AnzPers || $t('notice.empty')]" :error-messages="errorMessages" required></v-text-field>
                        </v-col>

                        <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="zielcountry" v-model="zielcountry" :rules="[() => !!country || $t('notice.empty')]" :items="countries" :label="$t('global.stern') + ' Zielland'" required></v-select>
                        </v-col>

                        
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="ZielPLZ" v-model="ZielPLZ" :rules="rules.zipRules" :label="$t('global.stern') + ' Ziel PLZ'" required></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="ZielOrt" v-model="ZielOrt" :rules="[() => !!ZielOrt || $t('notice.empty')]" :label="$t('global.stern') + ' Zielort'" required></v-text-field>
                        </v-col>

                        <!-- <v-time-picker v-model="uhrhin" format="24hr"></v-time-picker> -->


                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-textarea ref="formmessage" outlined label="geplanter Reiseverlauf" v-model="formmessage" :rules="[() => !!formmessage || $t('notice.empty')]" :error-messages="errorMessages" required></v-textarea>
                        </v-col>

                       
                   





                    </v-row>
                    <v-row>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div>{{ $t("global.datenschutz") }} <a href="#/article/Impressum" >Impressum</a> und <a href="#/article/Datenschutz" >Datenschutz</a></div>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div>
                                <v-checkbox ref="akzeptieren" v-model="akzeptieren" :label="$t('global.agbunddatens')" :rules="[() => !!akzeptieren || $t('notice.empty')]" :error-messages="errorMessages" required></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pt-3">
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="info" width="100%" @click="resetForm()">{{ $t("registration.deleteform") }}</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="primary" width="100%" @click="submit()">{{ $t("global.abschicken") }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>

        <v-dialog v-model="dialogDanke" width="500" >
            <v-card> 
                <v-card-title class="headline grey lighten-2" primary-title >
                    {{$t('forms.mailversendet')}}
                    </v-card-title>
                <v-card-text class="pt-5">
                    {{$t('forms.nachricht')}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions> <v-spacer></v-spacer> 
                <v-btn color="primary" text @click.stop="navigate('/')" > {{$t('global.close')}} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



    </v-container>
</template>

<script>
import { mapState } from "vuex"
import moment from 'moment'

import VueRecaptcha from "vue-recaptcha"
export default {
    components: {
        VueRecaptcha
    },
    data() {
        return {
            
            TimeAb: null,
            menuTimeAb: false,
            TimeAn: null,
            menuTimeAn: false,
            kundenint: [],   
            interessen: null,
            timean:null,
            timeab:null,
            timepickerab:true,
            timepickeran:false,
            zielcountry:null,
            ZielOrt:null,
            ZielPLZ:null,
            termhin:null,
            termrueck:null,
            AnzPers:null,
            uhrhin:null,
            uhrrueck:null,
            datepicker2: new Date().toISOString().substr(0, 10), 
            datepickerMenu2: false,  
            datepicker: new Date().toISOString().substr(0, 10), 
            datepickerMenu: false, 
            altertermin:null,   
            termin: null, 
            saluation_select: null,
            titel_select: null,
            valid: false,
            countries: [],
            salutation: [],
            titel: [],
            position: [],
            company: [],
            akzeptieren: null,
            firstname: null,
            lastname: null,
            address: null,
            city: null,
            zip: null,
            country: null,
            tel: null,
            email: null,
            formmessage: null,
            formHasErrors: false,
            errorMessages: "",
            rules: {
                zipRules: [v => !!v || this.$t("registration.ziperror"), v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || this.$t("registration.ziperror")]
            },
            isCaptcha: true,
            zweiterSchluessel: "6LdTarUUAAAAAMXMsm1xxDYnLuY_X0gCDimYoaiq"
        }
    },
    computed: {
        ...mapState(["UserStore", "WebsiteStore"]),

        form() {
            return {
                Formular: "Busmieten",
                Organisation: this.company,
                Anrede: this.saluation_select,
                Titel: this.titel_select,
                Vorname: this.firstname,
                Nachname: this.lastname,
                Adresse: this.address,
                PLZ: this.zip,
                Stadt: this.city,
                Land: this.country,
                Telefon: this.tel,
                Email: this.email,
                Interesse: this.kundenint,
                Hinfahrt: this.termhin,
                Rückfahrt: this.termrueck,
                Abfahrtszeit: this.TimeAb,
                Ankunftszeit: this.TimeAn,
                Personenanzahl: this.AnzPers,
                Zielland: this.zielcountry,
                ZielPLZ: this.ZielPLZ,
                ZielOrt: this.ZielOrt,
                Nachricht: this.formmessage,
                Datenschutz: this.akzeptieren
            }
        }
    },
    mounted() {
        Object.keys(this.$t("forms.anrede")).forEach(a => {
            this.salutation.push(this.$t("forms.anrede." + a))
        })
        Object.keys(this.$t("forms.titel")).forEach(a => {
            this.titel.push(this.$t("forms.titel." + a))
        })
        Object.keys(this.$t("forms.countries")).forEach(a => {
            this.countries.push(this.$t("forms.countries." + a))
        })
        
        Object.keys(this.$t("forms.interressen")).forEach(a => {
            this.kundenint.push(this.$t("forms.interressen." + a))
        })
    },
    created() {},
    destroyed() {},
    methods: {
        navigate(link, forceLoad = false) { 
            core.navigate({ path: link }, forceLoad)
        },
        setTermin(set) { 
            if(set==1) 
                { 
                    this.termhin = moment(String(this.datepicker)).format('DD.MM.YYYY');
                } 
            this.datepickerMenu = false;
            
        },
        setTermin2(set) { 
            if(set==1) 
                { 
                    this.termrueck = moment(String(this.datepicker2)).format('DD.MM.YYYY');
                } 
            this.datepickerMenu2 = false; 
        },
        resetForm() {
            this.$refs["Busanmieten"].reset()
            this.WebsiteStore.drawer = false
        },
         submit() {
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) {
                    this.formHasErrors = true
                }
            })

            if (this.$refs.Busanmieten.validate()) {
                let formData = new FormData()
                for (const key in this.form) {
                    formData.append(key, this.form[key])
                }

                fetch(process.env.BASE_URL + "php/mail_action.php", { method: "POST", body: formData })
                    .then(res => res.text())
                    .then(jsonData => {
                        this.resetForm()                        
                        this.dialogDanke = true;                        
                        this.dialogmessage = this.$t('forms.nachricht');
                        this.dialoghead= this.$t('forms.mailversendet');                    
                    })
                    .catch(error => {                        
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.errornachricht');
                        this.dialoghead= this.$t('forms.fehler');
                        return
                    })
            }
        }
    }
}
</script>


<style lang="scss">
</style>