<template>     
       <v-container grid-list-xl class="pa-0 us-form-mother">
            
             
            <v-form ref="Kataloganforderung">  

              <v-card elevation="0" > 
                <v-card-text>               
                  <v-row  dense >
                     <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                       <v-select outlined dense ref="salutation"  v-model="saluation_select" :items="salutation" :label="$t('forms.labelanrede')" ></v-select>                    
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-select outlined dense ref="titel" v-model="titel_select" :items="titel" :label="$t('forms.labeltitel')" ></v-select>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="firstname" dense v-model="firstname" :rules="[() => !!firstname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.vorname')"  required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="lastname" dense v-model="lastname" :rules="[() => !!lastname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.nachname')"  required ></v-text-field>
                    </v-col>

                    <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="company" dense v-model="company" :rules="[() => !!company || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.company')"  required ></v-text-field>
                    </v-col> -->
                    
                    <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="position" dense v-model="position" :rules="[() => !!position || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.position')"  required ></v-text-field>
                    </v-col> -->
                    
                   
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="email" dense v-model="email" :rules="[() => !!email || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.email')"  required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                      <v-text-field outlined  ref="tel" dense v-model="tel" :rules="[() => !!tel || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') +' '+  $t('forms.tel')"  required ></v-text-field>
                    </v-col>
                   

                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                      <v-text-field outlined  dense  ref="address" v-model="address" :rules="[ () => !!address || $t('notice.empty'), () => !!address && address.length <= 25 || $t('notice.minlang5') ]" :label="$t('global.stern') +' '+  $t('forms.strasse')" required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                        <v-text-field outlined  dense ref="zip" v-model="zip" :rules="rules.zipRules"  :label="$t('global.stern') +' '+  $t('forms.plz')" required ></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">          
                        <v-text-field outlined  dense ref="city" v-model="city" :rules="[() => !!city || $t('notice.empty') ]" :label="$t('global.stern') +' '+  $t('forms.ort')"  required ></v-text-field>
                    </v-col>                                  
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                      <v-select outlined dense ref="country" v-model="country" :rules="[() => !!country || $t('notice.empty')]" :items="countries" :label="$t('global.stern') +' '+  $t('forms.land')" required ></v-select>
                    </v-col>
                    <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12">
                      <v-checkbox dense v-model="hk" color="primary" true-value="ja" false-value="nein" :label="$t('global.hk')"></v-checkbox>
                    </v-col>
                    <!-- <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12">                
                      <v-checkbox dense v-model="kn" color="primary" true-value="ja" false-value="nein" :label="$t('global.kn')"></v-checkbox>
                    </v-col> -->

                    <!-- <v-col cols="12" xl="7" lg="7" md="7" sm="7" xs="12">          
                      <v-checkbox  dense  v-model="aws" color="primary" true-value="ja" false-value="nein"  :label="$t('global.aws')"></v-checkbox>                    
                    </v-col>
                    <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12">          
                      <v-checkbox  dense  v-model="vb" color="primary" true-value="ja" false-value="nein"  :label="$t('global.vb')"></v-checkbox>                    
                    </v-col>
                    <v-col cols="12" xl="7" lg="7" md="7" sm="7" xs="12">          
                      <v-checkbox  dense  v-model="ok" color="primary" true-value="ja" false-value="nein"  :label="$t('global.ok')"></v-checkbox>                    
                    </v-col> -->
      
                    <!-- <v-col cols="12" class="mt-3">          
                      <VueRecaptcha sitekey="6LdTarUUAAAAABJ9PzpzT7rmM8GNclGvVRlm2VEV" class="us-make-responsiv" @verify="captcha = true"></VueRecaptcha>
                    </v-col>  -->
                  </v-row>
                  <v-row>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12"> 
                        <div>{{ $t("global.datenschutz") }} <a href="#/article/Impressum" >Impressum</a> und <a href="#/article/Datenschutz" >Datenschutz</a></div>           </v-col>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
                        <div>
                            <v-checkbox ref="akzeptieren" v-model="akzeptieren" :label="$t('global.agbunddatens')" :rules="[() => !!akzeptieren || $t('notice.empty')]" :error-messages="errorMessages"  required ></v-checkbox>
                        </div>
                    </v-col>
                  </v-row>

                  <v-row class="pt-3">
                        <v-col cols="12" xs="12" sm="6">
                              <v-btn color="info" width="100%" @click="resetForm()" >{{$t('registration.deleteform')}}</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="primary" width="100%" @click="submit()" >{{$t('global.abschicken')}}</v-btn> 
                        </v-col>
                  </v-row> 
                              
              </v-card-text>
            </v-card>
          </v-form>  



          <v-dialog v-model="dialogDanke" width="500" >
                <v-card> 
                  <v-card-title class="headline grey lighten-2" primary-title >
                    {{dialoghead}}
                  </v-card-title>
                <v-card-text class="pt-5">
                  {{dialogmessage}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions> <v-spacer></v-spacer>                 
                <v-btn color="primary" text @click.stop="navigate('/')" > {{$t('global.close')}} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-overlay :value="overlayEmail" style="z-index:9999;">                  
            <div class="d-flex align-center justify-center flex-column">
                <v-progress-circular indeterminate size="64" ></v-progress-circular> 
                <span class="pt-5">Ihre Daten werden verarbeitet.</span>
            </div>  
        </v-overlay>


        </v-container>


</template>

<script>    
  
    import { mapState } from "vuex"
  
  import VueRecaptcha from 'vue-recaptcha';
    export default {   
    components: { 
      VueRecaptcha 
    },
    data() {
      return {
        overlayEmail: false,
        
        dialogDanke:false,
        dialogmessage:null,

        dialoghead:null,
        saluation_select:null,
        titel_select:null,
        valid: false,       
        countries: [],
        salutation: [],
        titel: [],        
        position:null,
        akzeptieren:null,
        company:null,
        firstname: null,
        lastname: null,
        address: null,
        city: null,
        zip: null,
        hk:'Nein',
        // kn:'Nein',
        aws:'Nein',
        vb:'Nein',
        ok:'Nein',
        country: null,
        formHasErrors: false,
        errorMessages: '',
        tel: null,
        email: null,
        rules: {
            zipRules: [v => !!v || this.$t('registration.ziperror') , v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v)  || this.$t('registration.ziperror') ],          
        },        
        isCaptcha:true,
        zweiterSchluessel:'6LdTarUUAAAAAMXMsm1xxDYnLuY_X0gCDimYoaiq',        
      };
    },
    computed: {
        ...mapState(["UserStore","WebsiteStore"]),

        form () {
            return {
              Formular: 'Katalog Bestellung',
              Anrede:this.saluation_select,
              Titel:this.titel_select,
              Vorname: this.firstname,
              Nachname: this.lastname,              
              Strasse: this.address,
              Stadt: this.city,
              PLZ: this.zip,              
              Land: this.country,
              Telefon: this.tel,
              Email: this.email,   
              Firma: this.company,
              Position: this.position,
              AGB:this.akzeptieren,
              Hauptkatalog:this.hk,
              // KatalogNeu: this.kn,
            }
        }
    },
    mounted(){  
        Object.keys(this.$t('forms.anrede')).forEach(a => { this.salutation.push( this.$t('forms.anrede.'+a) ) })   
        Object.keys(this.$t('forms.titel')).forEach(a => { this.titel.push( this.$t('forms.titel.'+a) ) })   
        Object.keys(this.$t('forms.countries')).forEach(a => { this.countries.push( this.$t('forms.countries.'+a) ) })   
    },   
    created () {      
       
    },
    destroyed () {       
        
    },
    methods: {                  
          navigate(link, forceLoad = false) { 
            core.navigate({ path: link }, forceLoad)
          },
          resetForm () {
            this.$refs['Kataloganforderung'].reset();
            this.WebsiteStore.drawer = false;
          },
          submit () {
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) {
                    this.formHasErrors = true
                }
            })
            this.overlayEmail = true;
            if (this.$refs['Kataloganforderung'].validate()) {
                let formData = new FormData()
                for (const key in this.form) {
                    formData.append(key, this.form[key])
                }

                fetch(process.env.BASE_URL + "php/mail_action.php", { method: "POST", body: formData })
                    .then(res => res.text())
                    .then(jsonData => {
                        this.resetForm()
                        this.overlayEmail = false;
                        
                        this.dialogDanke = true;
                        
                        this.dialogmessage = this.$t('forms.nachricht');
                        this.dialoghead= this.$t('forms.mailversendet');
                     
                    })
                    .catch(error => {
                        this.overlayEmail = false;
                        
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.errornachricht');
                        this.dialoghead= this.$t('forms.fehler');
                        
                        return
                    })
            }
      
      
      
      
      
      
      },
    }  
      
  }
</script>


<style lang="scss">

</style>