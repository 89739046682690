<template>
    <v-container grid-list-xl class="pa-0 us-form-mother">
    
        <v-form ref="Gutschein">
            <v-card elevation="0">
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12"> 
                            <h3 class="primary--text mt-n3  ml-n3"> Verschenken Sie doch eine Reise!</h3> 
                        </v-col> 
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="salutation" v-model="saluation_select" :items="salutation" :label="$t('forms.labelanrede')"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-select outlined dense ref="titel" v-model="titel_select" :items="titel" :label="$t('forms.labeltitel')"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="firstname" dense v-model="firstname" :rules="[() => !!firstname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.vorname')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="lastname" dense v-model="lastname" :rules="[() => !!lastname || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.nachname')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-text-field outlined  dense ref="address" v-model="address" :rules="[() => !!address || $t('notice.empty'), () => (!!address && address.length <= 25) || $t('notice.minlang5')]" :label="$t('global.stern') + ' ' + $t('forms.strasse')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="zip" v-model="zip" :rules="rules.zipRules" :label="$t('global.stern') + ' ' + $t('forms.plz')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  dense ref="city" v-model="city" :rules="[() => !!city || $t('notice.empty')]" :label="$t('global.stern') + ' ' + $t('forms.ort')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-select outlined dense ref="country" v-model="country" :items="countries" :label="$t('forms.land')" ></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="email" dense v-model="email" :rules="[() => !!email || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.email')" required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="tel" dense v-model="tel" :rules="[() => !!tel || $t('notice.empty')]" :error-messages="errorMessages" :label="$t('global.stern') + ' ' + $t('forms.tel')" required></v-text-field>
                        </v-col> 
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="betrag" :rules="[() => !!betrag || $t('notice.empty')]" :error-messages="errorMessages" dense v-model="betrag"  :label="$t('global.stern') + ' Gutscheinbetrag'"  required></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
                            <v-text-field outlined  ref="empfaenger" :rules="[() => !!empfaenger || $t('notice.empty')]" :error-messages="errorMessages" dense v-model="empfaenger" :label="$t('global.stern') + ' Gutscheinempfänger'" required ></v-text-field>
                        </v-col>                       
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <v-textarea ref="formmessage" outlined :label="$t('forms.message')" v-model="formmessage" :rules="[() => !!formmessage || $t('notice.empty')]" :error-messages="errorMessages" required></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div>{{ $t("global.datenschutz") }} <a href="#/article/Impressum" >Impressum</a> und <a href="#/article/Datenschutz" >Datenschutz</a></div>
                        </v-col>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div>
                                <v-checkbox ref="akzeptieren" v-model="akzeptieren" :label="$t('global.agbunddatens')" :rules="[() => !!akzeptieren || $t('notice.empty')]" :error-messages="errorMessages" required></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pt-3">
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="info" width="100%" @click="resetForm()">{{ $t("registration.deleteform") }}</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn color="primary" width="100%" @click="submit()">{{ $t("global.abschicken") }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>


        <v-dialog v-model="dialogDanke" width="500" >
            <v-card> 
                <v-card-title class="headline grey lighten-2" primary-title >
                    {{$t('forms.mailversendet')}}
                    </v-card-title>
                <v-card-text class="pt-5">
                    {{$t('forms.nachricht')}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions> <v-spacer></v-spacer> 
                <v-btn color="primary" text @click.stop="navigate('/')" > {{$t('global.close')}} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="overlayEmail" style="z-index:9999;">                  
            <div class="d-flex align-center justify-center flex-column">
                <v-progress-circular indeterminate size="64" ></v-progress-circular> 
                <span class="pt-5">Ihre Daten werden verarbeitet.</span>
            </div>  
        </v-overlay>

    </v-container>
</template>

<script>    
  
    import { mapState } from "vuex"
    import moment from 'moment'
    import VueRecaptcha from 'vue-recaptcha';

    export default {   
    components: { 
      VueRecaptcha 
    },
    data() {
      return {
        overlayEmail: false,
        empfaenger:null,
        betrag:null, 
        dialogDanke:false,
        dialogmessage:null,
        dialoghead:null,
        saluation_select:null,
        titel_select:null,
        akzeptieren:false,
        valid: false,       
        countries: [],
        salutation: [],
        titel: [],  
        firstname: null,
        lastname: null,
        address: null,
        city: null,
        zip: null,
        country: null,
        tel: null,
        email: null,
        formmessage: null,
        formHasErrors: false,
        errorMessages: '',
        rules: {
            zipRules: [v => !!v || this.$t('registration.ziperror') , v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v)  || this.$t('registration.ziperror') ],          
        },        
        isCaptcha:true,
        zweiterSchluessel:'6LdTarUUAAAAAMXMsm1xxDYnLuY_X0gCDimYoaiq',        
      };
    },    
    computed: {
        ...mapState(["UserStore","WebsiteStore"]),

        form () {
            return {
              Formular: 'Reisegutschein',              
              Titel: this.saluation_select,
              Anrede: this.titel_select,
              Vorname: this.firstname,
              Nachname: this.lastname,
              Straße: this.address,
              PLZ: this.zip,
              Ort: this.city,
              Land:this.country,
              Telefon: this.tel,
              Email: this.email,
              Telefon: this.tel,             
              Gutscheinbetrag: this.betrag,             
              Empfänger: this.empfaenger,             
              Anliegen: this.formmessage
        }
        }
    },
    mounted(){  
        Object.keys(this.$t('forms.anrede')).forEach(a => { this.salutation.push( this.$t('forms.anrede.'+a) ) })   
        Object.keys(this.$t('forms.titel')).forEach(a => { this.titel.push( this.$t('forms.titel.'+a) ) })   
        Object.keys(this.$t('forms.countries')).forEach(a => { this.countries.push( this.$t('forms.countries.'+a) ) })   
    },   
    created () {      
       
    },
    destroyed () {       
        
    },
    methods: {                  
        navigate(link, forceLoad = false) { 
            core.navigate({ path: link }, forceLoad)
        },
        setTermin(set) { 
            if(set==1) 
                { 
                    this.termin = moment(String(this.datepicker)).format('DD.MM.YYYY');; 
                } 
            this.datepickerMenu = false; 
        },
          setTermin2(set) { 
            if(set==1) 
                { 
                    this.altertermin = moment(String(this.datepicker2)).format('DD.MM.YYYY');; 
                } 
            this.datepickerMenu2 = false; 
        },
          resetForm () {
            this.$refs['Gutschein'].reset();
            this.WebsiteStore.drawer = false;
          },
          submit() {
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) {
                    this.formHasErrors = true
                }
            })
            this.overlayEmail = true;
            if (this.$refs.Gutschein.validate()) {
                let formData = new FormData()
                for (const key in this.form) {
                    formData.append(key, this.form[key])
                }

                fetch(process.env.BASE_URL + "php/mail_action.php", { method: "POST", body: formData })
                    .then(res => res.text())
                    .then(jsonData => {
                        this.resetForm()
                        this.overlayEmail = false;                        
                        this.dialogDanke = true;                        
                        this.dialogmessage = this.$t('forms.nachricht');
                        this.dialoghead= this.$t('forms.mailversendet');                    
                    })
                    .catch(error => {
                        this.overlayEmail = false;                        
                        this.dialogDanke = true;
                        this.dialogmessage = this.$t('forms.errornachricht');
                        this.dialoghead= this.$t('forms.fehler');
                        return
                    })
            }
        }
    }  
      
  }
</script>


<style lang="scss">
    .v-icon.notranslate {
        margin-left:5px;
    }
</style>