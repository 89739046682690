<template>

  <div class="d-center-content fill-height" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">

    <div class="d-skew-mother">
    <!-- <div class="d-skew-mother" v-if="headline != 'Newsletter'"> -->
      <div class="d-skew-left px-10" >{{headline}}</div>
      <div class="d-skew-center"></div>
      <div class="d-skew-right"></div>
      <div class="d-skew-abdecker "></div>
      <div class="d-skew-abdecker-white "></div>
    </div>
    <div class="d-content-content">

        <anfrage-form v-if="loadform == 'AnfrageForm'"></anfrage-form>
        <Kataloganforderung v-if="loadform == 'Kataloganforderung'"></Kataloganforderung>
        <OrderNewsletter v-if="loadform == 'OrderNewsletter'"></OrderNewsletter>
        <AnfrageJourney v-if="loadform == 'AnfrageJourney'"></AnfrageJourney>
        <Anfragepauschal v-if="loadform == 'Anfragepauschal'"></Anfragepauschal>
        <Busanmieten v-if="loadform == 'Busanmieten'"></Busanmieten>
        <Gutschein v-if="loadform == 'Gutschein'"></Gutschein>
        <Newsletter v-if="loadform == 'Newsletter'"></Newsletter>
        <Job v-if="loadform == 'Job'"></Job>

    </div>

  </div>
</template>

<script>

    import AnfrageForm from "@/components/Forms/AnfrageForm"
    import Kataloganforderung from "@/components/Forms/Kataloganforderung"
    import OrderNewsletter from "@/components/Forms/OrderNewsletter"
    import AnfrageJourney from "@/components/Forms/AnfrageJourney"
    import Anfragepauschal from "@/components/Forms/Anfragepauschal"
    import Busanmieten from "@/components/Forms/Busanmieten"
    import Gutschein from "@/components/Forms/Gutschein"
    import Newsletter from "@/components/Forms/Newsletter"
    import Job from "@/components/Forms/Job"




  export default {

    data() {
        return {
          loadform:null,
          headline:null,
          translateHeadline:[
            {name:'AnfrageForm', headline:'Schreiben Sie uns'},
            {name:'Kataloganforderung', headline:'Katalog anfordern'},
            {name:'OrderNewsletter', headline:'Newsletteranmeldung'},
            {name:'AnfrageJourney', headline:'Buchungsanfrage'},
            {name:'Anfragepauschal', headline:'Reisewunsch'},
            {name:'Gutschein', headline:'Gutschein'},
            {name:'Busanmieten', headline:'Busanmieten'},
            {name:'Newsletter', headline:'Newsletter'},
            {name:'Job', headline:'Jetzt umsteigen!'},

          ]
        }
    },
    mounted() {
      this.loadform = core.getPathArgs(["id"]).id;
      this.setheadline();
    },
    watch: {
        $route(to, from) {
          this.loadform = to.params.forms;
          this.setheadline();
        }
    },
    components: {
        AnfrageForm,
        Kataloganforderung,
        OrderNewsletter,
        AnfrageJourney,
        Anfragepauschal,
        Busanmieten,
        Gutschein,
        Newsletter,
        Job,
    },
    methods: {
      setheadline(){
        for(var a=0; a<this.translateHeadline.length; a++){
          if(this.translateHeadline[a].name == this.loadform) {
            this.headline = this.translateHeadline[a].headline;
            a = this.translateHeadline.length
          }
        }
      }

    },

};
</script>

<style lang="scss">


</style>
